.elc-product-full {
  .elc-vto-foundation {
    &-shade-grid {
      .elc-vto {
        &-see-pair-product {
          border: 0;
          text-align: $rdirection;
          text-decoration: underline;
        }
        &-tab-container {
          margin-bottom: 8px;
        }
      }
    }
    .elc-product-cta-wrapper {
      .elc-product-notify-me-button {
        font-size: 14px;
        height: 48px;
        line-height: normal;
      }
    }
  }
}
