#bt_settings {
  .bt-content {
    .slide-toggle {
      background-image: url('/media/export/images/cookies/m_all-off_no.png');
      background-size: 100% 100%;
      @media #{$medium-up} {
        background-image: url('/media/export/images/cookies/all-off_no.png');
      }
    }
    .mask {
      .slide-toggle {
        background-image: url('/media/export/images/cookies/m_all-on_no.png');
        background-size: 100% 100%;
        @media #{$medium-up} {
          background-image: url('/media/export/images/cookies/all-on_no.png');
        }
      }
    }
  }
}
