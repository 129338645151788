.clinical-reality {
  .mobile_hidden {
    @include start-breakpoint($medium-m) {
      max-height: 1100px;
    }
    @include start-breakpoint($medium-max) {
      max-height: inherit;
    }
  }
}

.section-clinicalreality {
  .nav-promo {
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
  .main {
    clear: both;
  }
}

.clinique-reality-overlay {
  iframe {
    height: 380px;
  }
}
